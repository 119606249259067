@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

#projetos {
  padding: 50px;
  background-color: #282c34;
  text-align: center;
  color: #6ec0d4;
  z-index: 1;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.projeto {
  perspective: 1000px;
}

.flip-card {
  width: 100%;
  height: 400px;
  position: relative;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s ease;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 12px;
  overflow: hidden;
}

.flip-card-front {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-size: cover;
  background-position: center;
  color: #fff;
  font-family: 'Righteous', sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 10px;
}

.flip-card-back {
  color: #fff;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #333;
  border-radius: 12px;
}

h3 {
  background-color: rgba(133, 133, 133, 0.7); /* Fundo semi-transparente em cinza */
  font-family: 'Righteous', sans-serif;
  font-size: 1.8rem;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.98);
  text-transform: uppercase;
  padding: 10px;
  text-align: center;
}

p {
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 1px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

.button-wrapper {
  margin-top: 18px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.btn {
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 0.9rem;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all .3s ease;
}

.outline {
  background: transparent;
  color: rgba(0, 212, 255, 0.9);
  border: 1px solid rgba(0, 212, 255, 0.6);
}

.outline:hover {
  transform: scale(1.125);
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.9);
}

.fill {
  background: rgba(0, 212, 255, 0.9);
  color: rgba(255, 255, 255, 0.95);
}

.fill:hover {
  transform: scale(1.125);
  filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.125));
}

/* Responsividade */
@media (max-width: 768px) {
  #projetos h2 {
    font-size: 28px;
  }

  .flip-card {
    height: 350px;
  }

  .flip-card-front,
  .flip-card-back {
    padding: 15px;
  }

  .btn {
    font-size: 0.75rem;
  }
}
