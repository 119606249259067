* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif;
    background-color: #282c34;
    color: #6ec0d4;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.container {
    text-align: center;
    padding-top: 5rem;
}

.message {
    margin-bottom: 20px;
}

h1 {
    font-size: 3rem;
    margin-bottom: 10px;
}

p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.loader {
    border: 10px solid #6ec0d4;
    border-top: 10px solid transparent;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
