.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px; /* Aumente o padding se necessário */
  background-color: #282c34;
  color: #6ec0d4;
  position: relative; 
  z-index: 1000; /* Mantenha o z-index alto para que o header fique acima */
}

.contact {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.contact p {
  margin-right: 10px;
}

.logo {
  margin-top: 2rem;
}

.logo img {
  height: 100px;
}

.menu-anchor {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40px;
  height: 32px;
  background: transparent;
  margin-left: auto;
}

.menu-anchor span {
  display: block;
  width: 100%;
  height: 4px;
  background: #6ec0d4;
  margin: 3px 0;
  transition: background 0.25s;
}

.nav {
  position: fixed; /* A posição fixa pode causar sobreposição */
  top: 0;
  right: -220px;
  width: 220px;
  height: 100%;
  background: #282c34;
  transition: right 0.25s ease;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.3);
  z-index: 999; /* Certifique-se de que o menu esteja acima de outros elementos */
}

.nav ul {
  list-style-type: none;
  padding: 10px 0;
}

.nav a {
  cursor: pointer;
  display: block;
  padding: 10px;
  color: #fff;
  text-decoration: none;
}

.nav a:hover {
  background: #fff;
  color: #d55401;
}

.menu-active {
  right: 0;
}

@media (max-width: 100px) {
  .header {
    flex-direction: column;
    align-items: center;
  }

  .logo img {
    height:80px;
  }

  .contact p {
    font-size: 10px;
  }

  .contact a svg {
    width: 20px;
    height: 20px; 
}
.contact {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.contact a {
  margin-right: 10px;
}
.menu-anchor {
  display: flex;
  align-items: center;

  
}
}
 