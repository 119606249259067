body {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Inclui padding e borda nas dimensões */
}

*,
*::before,
*::after {
  box-sizing: inherit; /* Aplica box-sizing a todos os elementos */
}

/*@media (max-width: 600px) {
  body {
    background-color: lightgray;
    font-size: 14px;
}
}
*/