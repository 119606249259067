/* Estilo geral do rodapé */
.footer {
  padding: 20px; /* Adiciona espaço interno ao redor do conteúdo do rodapé */
  background-color: #282c34; /* Define a cor de fundo do rodapé */
  color: #6ec0d4; /* Define a cor do texto no rodapé */
  text-align: center; /* Centraliza o texto dentro do rodapé */
}

/* Estilo para o conteúdo do rodapé */
.footer-content {
  max-width: 800px; /* Define uma largura máxima para o conteúdo do rodapé */
  margin: 0 auto; /* Centraliza o conteúdo horizontalmente na tela */
}

/* Estilo para os links sociais */
.social-links {
  margin-top: 10px; /* Adiciona espaço acima dos links sociais */
}

/* Estilo para os links sociais individuais */
.social-links a {
  color: #6ec0d4; /* Define a cor do texto dos links sociais */
  text-decoration: none; /* Remove o sublinhado dos links */
  margin: 0 10px; /* Adiciona margem horizontal entre os links */
}

/* Estilo para o efeito de hover nos links sociais */
.social-links a:hover {
  text-decoration: underline; /* Adiciona sublinhado ao passar o mouse sobre os links */
}
