.about-me {
    padding: 40px 20px;
    background-color: #282c34;
    text-align: center;
    color: #6ec0d4;
  }
  
  .about-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: #6ec0d4;
    font-family: 'Roboto', sans-serif;

  }
  
  
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column;
      align-items: center;

    }
  
    .profile-image {
      width: 300px;
      margin: 0 0 20px 0;
    }
  
    .description {
      max-width: 90%;
      text-align: center;
      font-size: 20px;

    }
  }

  
  h2 {
    margin-bottom: 20px;
    


  }
  
  h3 {
    margin-top: 20px;
   

  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    margin: 5px 0;
  }