/* Estilos Gerais */
.skills-section {
  padding: 60px 20px;
  background-color: #1e1e1e;
  text-align: center;
}

.heading {
  font-size: 36px;
  margin-bottom: 40px;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

.heading strong {
  color: #6ec0d4;
}

/* Container de Habilidades */
.skills-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 20px;
  justify-items: center;
  padding: 0 15px;
}

.skills-box {
  padding: 30px;
  border-radius: 12px;
  background-color: #333333;
  color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

.skills-box i {
  font-size: 50px;
  margin-bottom: 15px;
}

.skills-box h3 {
  font-size: 20px;
  font-weight: 600;
}

.skills-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.skills-box::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: -1;
  transition: background-color 0.3s ease;
}

.skills-box:hover::before {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Cores específicas para cada habilidade */
.html {
  background-color: #e44d26;
}

.css {
  background-color: #007acc;
}

.javascript {
  background-color: #f7df1e;
  color: #000;
}

.react {
  background-color: #61dbfb;
}

.git {
  background-color: #f05032;
}

.sql {
  background-color: #003b57;
}

.mongo {
  background-color: #4caf50;
}

.java {
  background-color: #f8981d;
}

/* Responsividade */
@media (max-width: 768px) {
  .heading {
    font-size: 28px;
  }

  .skills-box {
    padding: 20px;
    font-size: 14px;
  }

  .skills-box i {
    font-size: 40px;
  }

  .skills-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}
